<template>
  <div class="create__lot" v-if="!isLoad">
    <div class="block__subtitle mb-40">{{ formData.name }}</div>

    <form @submit.prevent class="form">
      <div class="form__inner">

        <div class="end mb-40">
          <VToggleDisplay />
        </div>

        <VTableCreateTrade
          v-if="isDisplayTable"
          :positions="formData.positions"
        />

        <VLotPositionList
          v-else
          isBidder
          :positions="formData.positions"
        />

        <div class="block__subtitle mb-40">
          <div class="form__subtitle">
            Сумма позиций: {{ formData.start_price || 0 }}
          </div>
        </div>

        <VLotParameters
          isBidder
          :is-edit-payment="isEditPayment"
          :is-edit-delivery="isEditDelivery"
          :form-data="formData"
          :minCriterionDisabled="minCriterionDisabled"
          :maxCriterionDisabled="maxCriterionDisabled"
          :validate-field="validateField"
          :v$="v$"
          :server-errors="serverErrors"

          :head-bidder="headBidder"
          :rows="rows"
        />

        <div>
          <div class="form__subtitle mb-40">Сопроводительная документация</div>
          <VLotParametersFile
            :is-bidder="isBidder"
            :form-data="formData"
            :server-errors="serverErrors"
          />
        </div>

        <div class="buttons-wrap">
          <button class="btn btn-primary btn-bg-green" :disabled="isSend" @click="onSubmit">Участвовать</button>
          <button type="button" class="btn btn-primary btn-bg-transparent" @click="$router.back()">Назад</button>
        </div>

      </div>
    </form>
  </div>
  <div
    v-else
    class="center"
  >
    <VSpinnerLoader />
  </div>
</template>

<script>
import { useTradeCreate } from '@/use/Form/Bidder/trade/useTradeCreate'
import VLotPositionList from '@/components/list/PositionList/VLotPositionList'
import VLotParameters from '@/components/views/Lot/Create/VLotParameters'
import { computed, inject, onBeforeMount, provide, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Observer } from '@/core/Observer'
import { useLotTable } from '@/use/Form/Lot/Create/useLotTable'
import VSpinnerLoader from '@/components/views/Spinner/VSpinnerLoader'
import { BIDDING_CONTROLLER, LOT_CONTROLLER } from '@/utils/consts'
import VToggleDisplay from '@/components/ui/buttons/VToggleDisplay'
import VTableCreateTrade from '@/components/views/VTableCreateTrade'
import VLotParametersFile from '@/components/views/Lot/Create/Part/VLotParametersFile'

export default {
  setup () {
    const biddingProcedureController = inject(BIDDING_CONTROLLER)
    const route = useRoute()
    const store = useStore()
    const isLoad = ref(false)
    const offers = reactive({})
    const isDisplayTable = computed(() => store.getters['display/getIsDisplayTable'])

    const observer = new Observer()
    const lotController = inject(LOT_CONTROLLER)
    provide('observer', observer)

    onBeforeMount(async () => {
      try {
        isLoad.value = true
        const lotId = route.params.id
        const data = await biddingProcedureController.getLastPrice(lotId)
        const response = await lotController.getLotById(lotId)
        store.commit('lot/setLot', response)
        Object.assign(offers, data)
        store.commit('lot/setLastPrice', offers)
      } catch (e) {
        console.trace()
      } finally {
        isLoad.value = false
      }
    })

    const {
      formData,
      validateField,
      v$,
      isEditPayment,
      isEditDelivery,
      isSend,
      serverErrors,
      onSubmit,
      minCriterionDisabled,
      maxCriterionDisabled
    } = useTradeCreate(biddingProcedureController, offers)

    const { headBidder, rows } = useLotTable(formData, null, null, true)

    return {
      isDisplayTable,
      onSubmit,
      serverErrors,
      formData,
      headBidder,
      rows,
      offers,
      validateField,
      v$,
      isEditDelivery,
      isEditPayment,
      isSend,
      isLoad,
      minCriterionDisabled,
      maxCriterionDisabled
    }
  },
  components: {
    VTableCreateTrade,
    VSpinnerLoader,
    VLotParameters,
    VLotPositionList,
    VToggleDisplay,
    VLotParametersFile
  }
}
</script>
